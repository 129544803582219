import React from 'react';

import Img from 'gatsby-image';

import tw from 'twin.macro';

import { Layout } from '@layouts';
import { Markdown, Section } from '@components';
import { useSeo, useJurisdiction } from '@queries';

const JurisdictionPage = () => {
  const { description_jurisdiction } = useSeo();
  const { map, _rawContent } = useJurisdiction();

  const siteTitle = 'Właściwość';

  return (
    <Layout title={siteTitle} description={description_jurisdiction}>
      <Section main heading={siteTitle} spaceTop>
        <Img
          alt="Mapa z zaznaczonym zakresem działań"
          fluid={map.asset.fluid}
          objectFit="contain"
          css={tw`w-full md:w-4/5 mx-auto mb-24`}
          imgStyle={{
            filter: 'drop-shadow(0 3px 5px rgba(0, 0, 0, 0.3))',
          }}
        />
        <Markdown blocks={_rawContent} />
      </Section>
    </Layout>
  );
};

export default JurisdictionPage;
